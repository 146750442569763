import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
// import MenuList from "../components/MenuList"

const IndexPage = () => (
  <Layout title="2020 Memorial Day Weekend Conference">
    <SEO title="2020 Memorial Day Weekend Conference"/>
    <section>
    
    <div className="container">
    <h3 className="title">General Subject: A Timely Word Concerning The World Situation And The Lord's Recovery</h3>
    <h2 className="title">Messages Titles:</h2>
    <blockquote>
      <ol>      
        <li>The World Situation as the Indicator of God’s Move on Earth, the Vision of World History from Christ’s Ascension to the End of This Age, the Spreading of the Truths of the Lord’s Recovery as a Preparation for His Coming Back, and Persevering in Prayer, While Discerning “the Signs of the Times.”</li>
        <li>Seeing a Vision of the Throne of God, the Spiritual Scene behind the World Situation, and Christ.</li>
        <li>The Universal History according to God’s Economy— the Divine History within the Human History.</li>
        <li>Praying Persistently with God as Our Faith.</li>
        <li>Answering God’s Call to Be His Dispensational Instrument, His Overcomers, to Turn the Age.</li>
        <li>The Preparation of the Bride.</li>
      </ol> </blockquote>
    <br></br>
    
    <h3 className="title">Key Statements:</h3>
    <blockquote>
      <ol>
        <li>We need to see the world situation as the indicator of God’s move on earth and the vision of world history from Christ’s ascension to the end of this age; we also need to work together with the Lord to spread the truths of the Lord’s recovery as a preparation for His coming back and to persevere in prayer, while discerning “the signs of the times.”</li>
        <li>We need to see a spiritual vision of the throne of God as the center of God’s administration throughout the universe and realize that the sovereign God has the full capacity to carry out what He wants according to the desire of His heart and according to His eternal economy, as He is ruling over everyone and everything.</li>
        <li>Today the way to become vitalized is to answer God’s call to be His overcomers, His dispensational instrument, to turn the age; an overcomer is a vital person, and a vital person (one who is living and active) is a praying person.</li>
        <li>The marriage of the Lamb is the issue of the completion of God’s New Testament economy, which is to obtain for Christ a bride, the church, through His judicial redemption and by His organic salvation in His divine life; the Lord’s recovery is for the preparation of the bride, who is composed of all His overcomers.</li>
      </ol></blockquote>   
      <br></br>
      <p>All verses are quoted from the <a href="https://www.recoveryversion.bible/" target="_blank" rel="noopener noreferrer">Holy Bible Recovery Version</a>.</p>
      <p>You can order a free copy of the New Testament Recovery Version <a href="http://biblesforamerica.org/free-bible/" target="_blank" rel="noopener noreferrer">here.</a></p>
    </div>
    </section>

    <style jsx>{`
      blockquote {
      text-align: justify;
      font-size: 1em;
      line-height: 1.5em;
      display: block;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 30px;
      margin-right: 75px;
    }
  `}</style>

</Layout>
)

export default IndexPage
